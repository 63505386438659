<template>
    <div>
        <HeaderNavigation :info="$store.state.infos" :checkValue="5" />
        <div class="top_pic"><img :src="banner" alt=""></div>
        <div class="content_box">
            <div class="content_title">{{Journalism.news_title}}</div>
            <div class="content_time">发布时间：{{Journalism.created_at}}</div>
            <div class="content_text" style="text-align: center" v-html="Journalism.news_introduction"></div>
            <div class="content_text" v-html="Journalism.new_content">
             </div>
        </div>
        <div class="bottom_btn">
            <div>
                <div @click="shang_click" v-if="last_id" class="pagebtn">上一页</div>
            </div>
            <div>
                <div @click="xia_click" v-if="next_id" class="pagebtn">下一页</div>
            </div>
        </div>
        <BottomNavigation :info="$store.state.infos" />
        <RightMenu :info="$store.state.infos" @openpop="openpop" />
        <JoinBulletFrame v-if="JoinModal" @closepop="closepop" />
    </div>
</template>

<script>
    import {new_xq,cateBanner} from '@/api/index.js'
    import HeaderNavigation from "@/components/header_navigation.vue";
    import BottomNavigation from "@/components/bottom_navigation.vue";
    import RightMenu from "@/components/right-menu.vue";
    import JoinBulletFrame from "@/components/joinBulletFrame.vue";
    export default {
        components: {
            HeaderNavigation,
            BottomNavigation,
            RightMenu,
            JoinBulletFrame
        },
        data(){
            return{
                JoinModal:false,
                Journalism:{},
                banner:'',
                last_id:0,
                next_id:0
            }
        },
        created() {
            window.scroll(0, 0)
            this.cateBanner()
            new_xq({id:this.$route.query.id}).then(res=>{
                this.Journalism = res.data.data
              console.log(this.Journalism)
                this.last_id = res.data.last_id
                this.next_id = res.data.next_id
            })
            // this.Journalism = JSON.parse(localStorage.getItem('Journalism'))
        },
        methods:{
            cateBanner(){
                cateBanner({id:5}).then(res=>{
                    this.banner = res.data
                })
            },
            shang_click(){
                new_xq({id:this.last_id}).then(res=>{
                    this.Journalism = res.data.data
                    this.last_id = res.data.last_id
                    this.next_id = res.data.next_id
                })
                window.scroll(0, 0)
            },
            xia_click(){
                new_xq({id:this.next_id}).then(res=>{
                    this.Journalism = res.data.data
                    this.last_id = res.data.last_id
                    this.next_id = res.data.next_id
                })
                window.scroll(0, 0)
            },
            openpop(e){
                this.JoinModal = e
            },
            closepop(e){
                this.JoinModal = e
            },
        }
    }
</script>

<style scoped>
.top_pic{
    width: 100%;
    height: 600px;
}
.top_pic img{
    width: 100%;
    height: 100%;
  object-fit: fill;
}
.content_box{
    width: 80%;
    margin: 30px auto 50px;
}
.content_title{
    color: #000;
    font-size: 28px;
    text-align: center;
}
.content_time{
    font-size: 16px;
    color: #999;
    text-align: center;
    margin: 20px 0;
}
.content_text{
    margin: 40px 0;
    font-size: 16px;
    color: #999;
}
.bottom_btn{
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
}
.pagebtn{
    /* background: #35755A; */
    border: 1px solid #35755A;
    color: #35755A;
    padding: 10px 30px;
    border-radius: 9px;
    cursor: pointer;
}
</style>